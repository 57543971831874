import React from 'react';
import ExeOne from '../../../assets/e-01.jpeg';
import ExeTwo from '../../../assets/e-02.jpeg';
import ExeFour from '../../../assets/e-04.jpg';
import './TeamStyles.css';

const Team = () => {
  return (
    <div name="team" className='team'>
        <div className="container">
            <h1>Our Executive Team</h1>
            <p>We work hard to deliver high quality services for our partners</p>
            <div className="team-content">
                <div className="team-card">
                    <img src={ExeOne} alt="" />
                    <div className="team-details">
                        <h3>Mr. Ngongo Dep-Joel Nkolomoni</h3>
                        <p>President</p>
                    </div>
                </div>

                <div className="team-card">
                    <img src={ExeFour} alt="" />
                    <div className="team-details">
                        <h3>Mr. Alain Roger Pagou</h3>
                        <p>CEO</p>
                    </div>
                </div>

                <div className="team-card">
                    <img src={ExeTwo} alt="" />
                    <div className="team-details">
                        <h3>Mr. Christian Kasiwa Ngoyi Mufuya</h3>
                        <p>General Secretary & Administrator</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Team