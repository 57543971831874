import React from 'react';
import BuzImage from '../../../assets/buz.png'
import './AboutStyles.css'

const About = () => {
  return (
    <div name="offer" className='about'>
      <div className="container">
        <div className="about-content">
          <div className='about-info'>
            <h1>Offers</h1>
            <p>* Facilitating Investments. Negotiating of various requests for financing bankable projects</p>
            <p>* Arbitrating transactions between a buyer and a seller</p>
            <p>* Intermediary role and connects the  Supply and Demand on the international chessboard.</p>
            <p>* Connect different partners through to business models. :PPP B2B B2C C2C</p>
            <p>* May behave as a Buyer-Aggregator who share the common goal of receiving discounts on goods and services for a percentage for each sale.</p>
          </div>
          <div className='about-img'>
            <img src={BuzImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About