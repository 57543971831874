import React from 'react';
import CongoImage from '../../../assets/congo-map.jpeg';
import './CompanyStyles.css';

const Company = () => {
  return (
    <div name="about" className="company">
        <div className="container">
            <h1>Learn More About Our Company</h1>
            <div className="company-content">
                <div className="company-image">
                    <img src={CongoImage} alt="" />
                </div>
                <div className="company-details">
                    <div className="aboutus">
                        <h3>About us</h3>
                        <p>GIGA Inc. was born from the merger of the know-how of three friends concerned with improving the daily lives of the countries where they go to invest. We have intrinsic and religious values ​​in addition to our diverse experiences to build together and leave a positive social impact.</p>
                    </div>
                    <div className="mission">
                        <h3>Mission</h3>
                        <p>We favor long-term support and seek to channel investors' capital into the real economy to help improve the state of food wherever we go. We establish responsible partnership relationships with our customers and businesses.</p>
                    </div>
                    <div className="vision">
                        <h3>Vision</h3>
                        <p>Faced with current challenges, a rapid transition towards a more resilient and sustainable economy is a necessity. Gica is called upon to play a role in this transition. By exercising its power of influence to encourage practical investments. And by advising accordingly. With a vision to become an essential pillar in our field.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Company