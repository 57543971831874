import React from 'react';
import MiningOne from '../../../assets/p-02.png';
import MiningTwo from '../../../assets/p-03.png';
import MiningThree from '../../../assets/p-04.png';
import MiningFour from '../../../assets/p-07.png';
import './ClientsStyles.css'

const Clients = () => {
  return (
    <div name="client" className="clients-stc">
        <div className='container'>
            <h1>Trusted Partners</h1>
            <p>We hold a great reputation to win the trust of our partners.</p>
            <div className="client-content">
                <div>
                    <img src={MiningOne} alt="" />
                </div>
                <div>
                    <img src={MiningTwo} alt="" />
                </div>
                <div>
                    <img src={MiningThree} alt="" />
                </div>
                <div>
                    <img src={MiningFour} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Clients