import React from 'react';

import './Mapview.css';

const Mapview = () => {
  return (
    <div className='container-map'>
      <div class="mapouter">     
        <div class="gmap_canvas">
          <iframe title="OurMap" class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=1020&amp;height=510&amp;hl=en&amp;q=5213 BROOKGLEN DR A, HOUSTON, TX 77017, USA&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
          </iframe>
        </div>
      </div>
    </div>
  )
}

export default Mapview