import React from 'react';
import { FaChartLine, FaHandshake, FaFlag, FaBullhorn } from 'react-icons/fa'
import './FieldStyles.css'

const Field = () => {
  return (
    <div name="field" className='container'>
        <h1 className='field-h1'>Area of Expertise</h1>
        <div className="field-contents">
            <div className="field-card-one">
                <div className="icon">
                    <FaBullhorn />
                </div>
                <div className="title">Advertisement Services</div>
                <div className="rating active-rating">99%</div>
            </div>
            <div className="field-card-two">
                <div className="icon">
                    <FaHandshake />
                </div>
                <div className="title">Investment Consulting</div>
                <div className="rating">200+</div>
            </div>
            <div className="field-card-three">
                <div className="icon">
                    <FaChartLine />
                </div>
                <div className="title">Business Advice</div>
                <div className="rating">50+</div>
            </div>
            <div className="field-card-four">
                <div className="icon">
                    <FaFlag />
                </div>
                <div className="title">Fraud and Risk</div>
                <div className="rating">20+</div>
            </div>
        </div>
    </div>
  )
}

export default Field