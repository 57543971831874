import React from 'react';
import './ContactStyles.css'

const Contact = () => {
  return (
    <div name="contact" className="contact">
        <div className="container">
            <form className="content-form">
                <input type="text" className="form-input" placeholder="Enter your name...." />
                <input type="email" className="form-input" placeholder="Enter your email..." />
                <textarea name="" id="" cols="30" rows="10" placeholder="Leave a message..."></textarea>
                <button type="submit" className="btn-form">Submit</button>
            </form>
        </div>
    </div>
  )
}

export default Contact