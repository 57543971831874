import './App.css';
import Footer from './components/footer/Footer';
import Landing from './components/landing/Landing';
import Navbar from './components/navbar/Navbar'
import About from './components/sections/About/About';
import Clients from './components/sections/clients/Clients';
import Contact from './components/sections/contact/Contact';
import Field from './components/sections/field/Field';
import Service from './components/sections/services/Service';
import Team from './components/sections/team/Team';
import Testimo from './components/sections/testimonials/Testimo';
import Mapview from './components/sections/ourmap/Mapview';
import Company from './components/sections/ourCompany/Company'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Landing />
      <Field />
      <Clients />
      <Service />
      <Company />
      <Testimo />
      <About />
      <Team />
      <Contact />
      <Mapview />
      <Footer />
    </div>
  );
}

export default App;
