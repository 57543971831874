import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import './TestimoStyles.css'

const Testimo = () => {
  return (
    <div name="testimony" className='testimony'>
         <div className="container">
            <h1>Satisfied Clients</h1>
            <p>We have helped small and medium businesses, large companies and individual in different sectors to invest and grow wealthy. We can help you too.</p>
            <div className="testimony-content">
                <div className="testimony-one">
                    <div className="icon">
                        <FaQuoteLeft />
                    </div>
                    <div className="t-content">
                        <p>No matter where you go, Consultant is the coolest, most happening thing around! Since I invested in Consultant I made over 100,000 dollars profits.</p>
                    </div>
                    <div className="t-info">
                        <h3>Sam Smith</h3>
                        <p>Director</p>
                    </div>
                </div>
                <div className="testimony-one testimo-active">
                    <div className="icon">
                        <FaQuoteLeft />
                    </div>
                    <div className="t-content">
                        <p>I am so pleased with this product. Thank you for making it painless, pleasant and most of all hassle free! Thanks guys, keep up the good work!</p>
                    </div>
                    <div className="t-info">
                        <h3>Pauline DeMarkov</h3>
                        <p>Founder/CEO</p>
                    </div>
                </div>
                <div className="testimony-one">
                    <div className="icon">
                        <FaQuoteLeft />
                    </div>
                    <div className="t-content">
                        <p>Not able to tell you how happy I am with Consultant. It really saves me time and effort. Consultant is exactly what our business has been lacking. I couldn't have asked for more than this.</p>
                    </div>
                    <div className="t-info">
                        <h3>Carlos Strickland</h3>
                        <p>Commercial Executive</p>
                    </div>
                </div>
            </div>
         </div>
    </div>
  )
}

export default Testimo