import React from 'react';
import { Link } from 'react-scroll';
import BuzOneImage from '../../assets/buz-01.jpeg';
import './LandingStyles.css';

const Landing = () => {
  return (
    <div className='landing'>
        <div className="container">
            <div className="content">
                <div className='content-info'>
                    <h1>GLOBAL <span className="title">INVESTMENTS</span> CONSULTANCY <span className="title">AGENCY</span> </h1>
                    <h2>In Business We Trust</h2>
                    <p>We are one of the leading global business consultant in the market.</p>
                    <button className='btn'><Link to="contact">Learn More</Link></button>
                </div>

                <div className='content-image'>
                    <img src={BuzOneImage} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Landing