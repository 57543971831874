import React from 'react';
import { FaFacebook, FaInstagram, FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa';
import GicaLog from '../../assets/gica-logo-01.png';
import './FooterStyles.css'

const Footer = () => {
  return (
    <div className="footer">
        <div className="container">
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={GicaLog} alt="" />
                </div>
                <div className='inner-footer-menu'>
                    <div className="footer-services">
                        <h3>Offers</h3>
                        <ul>
                            <li>Mining</li>
                            <li>Trade</li>
                            <li>Banking</li>
                            <li>Civil Works</li>
                            <li>Management</li>
                            <li>Transportation</li>
                            <li>Entrepreneurship</li>
                        </ul>
                    </div>
                    <div className="footer-menu">
                        <h3>Explore</h3>
                        <ul>
                            <li>Home</li>
                            <li>Services</li>
                            <li>About Us</li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                    <div className="footer-menu">
                        <h3>Contact Us</h3>
                        <ul>
                            <li><FaRegEnvelope /> info@gicainc.org</li>
                            <li><FaPhoneAlt /> +1 2819354240</li>
                            <li><FaPhoneAlt /> +46 737645005</li>
                            <li><FaPhoneAlt /> +33 753 204 490</li>
                        </ul>
                    </div>
                </div>
                <div className="footer-social-media">
                    <div className="facebook">
                        <a href='https://www.facebook.com/profile.php?id=100087453627300'><FaFacebook /></a> 
                    </div>
                    <div className="instagram">
                        <a href='https://www.instagram.com/invites/contact/?i=1f656hsq11vok&utm_content=pz4gaur'><FaInstagram /></a>                         
                    </div>
                </div>
            </div>
            <div className="rights">
                <hr />
                <p>All right reserved &copy; GICA Inc.</p>
            </div>
        </div>
    </div>
  )
}

export default Footer