import React from 'react';
import IndustryImage from '../../../assets/machine.jpeg';
import MiningImage from '../../../assets/mining.jpg';
import EnergyImage from '../../../assets/s-02.jpeg';
import TImage from '../../../assets/transport.jpeg';
import HealthImage from '../../../assets/s-03.jpeg';
import TravelImage from '../../../assets/s-06.jpeg';
import './ServiceStyles.css'

const Service = () => {
  return (
    <div name="service" className='service'>
        <div className="container">
            <h1 className='service-h1'>Our Services</h1>
            <p className='service-p'>We offer a variety of services to help more people achieve their businesses grow and reach their fullest potentials</p>

            <div className="service-content">
                <div className="service-card">
                    <div>
                        <img src={IndustryImage} alt="" />
                    </div>

                    <div className='service-details'>
                        <h3>Technology</h3>
                        <h3>Industrial Engineering</h3>
                    </div>
                </div>

                <div className="service-card">
                    <div>
                        <img src={EnergyImage} alt="" />
                    </div>

                    <div className='service-details'>
                        <h3>Infrastructure</h3>
                        <h3>Energy</h3>
                    </div>
                </div>

                <div className="service-card">
                    <div>
                        <img src={MiningImage} alt="" />
                    </div>
                    
                    <div className='service-details'>
                        <h3>Mining</h3>
                        <h3>Import - Export</h3>
                    </div>
                </div>

                <div className="service-card">
                    <div>
                        <img src={TImage} alt="" />
                    </div>
                    
                    <div className='service-details'>
                        <h3>Transport</h3>
                        <h3>Multimodal</h3>
                    </div>
                </div>
            
                <div className="service-card">
                    <div>
                        <img src={HealthImage} alt="" />
                    </div>

                    <div className='service-details'>
                        <h3>Health</h3>
                        <h3>Water & Agriculture</h3>
                    </div>
                </div>

                <div className="service-card">
                    <div>
                        <img src={TravelImage} alt="" />
                    </div>

                    <div className='service-details'>
                        <h3>Trade</h3>
                        <h3>Aeronautics</h3>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Service