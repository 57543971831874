import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa'
import LogoImage from '../../assets/gica-logo-01.png'
import './NavbarStyles.css'

const Navbar = () => {
    const [ nav, setNav ] = useState(false);
    const handleNav = () => setNav(!nav);
  return (
    <div className='container'>
        <div className="navbar">
            <div className="navbar-logo">
                <img src={LogoImage} alt="" />
            </div>
            <div className={nav ? 'navbar-menu active' : 'navbar-menu'}>
                <ul>
                    <li><Link to="home" spy={true} smooth={true} duration={500} >Home</Link></li>
                    <li><Link to="service" spy={true} smooth={true} duration={500} >Services</Link></li>
                    <li><Link to="about" spy={true} smooth={true} duration={500} >About Us</Link></li>
                    <li><Link to="offer" spy={true} smooth={true} duration={500} >Offers</Link></li>
                    <button><Link to="contact" spy={true} smooth={true} offset={50} duration={500} >Contact Us</Link></button>
                </ul>
            </div>
            <div className="menu-bar" onClick={handleNav} >
                {!nav ? ( <FaBars /> ) : ( <FaTimes /> ) }
            </div>
        </div>
    </div>
  )
}

export default Navbar